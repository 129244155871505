import React, { useEffect, useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import useSearchi18n from "@/i18n/useSearchi18n"

import { getShortenedUrl } from "@/utils/location"
import { aemAxiosInstance } from "@/constants/api"
import {
  ARTICLE_CONTENT_END_POINT,
  ARTICLE_IMG_END_POINT,
  GLOBAL_CONFIGURATION_ENDPOINT,
  baseUrl,
  publicWebsiteUrl,
} from "@/constants"

import {
  addMedia,
  removeItems,
  selectFavoritesState,
  setLoginFavouriteCallback,
} from "@/store/features/favoritesSlice"
import {
  selectAuthState,
  setAuthModalVisibility,
} from "@/store/features/authSlice"
import { selectGenericState, showToast } from "@/store/features/genericSlice"

const FavouritesUtility = ({
  title = "",
  assetID = "",
  imageServerUrl = "",
  alt = "",
  containerId: zone = "",
}) => {
  let isTriggered = false
  const dispatch = useDispatch()
  const [isFavourite, setIsFavourite] = useState(false)
  const [pageUrl, setPageUrl] = useState("")
  const [toasterContent, setToasterContent] = useState({
    successMessage: "",
    unfavoriteSuccessMessage: "",
  })
  const favEle = useRef(null)
  const {
    favorites: favoritesLabel,
    favSuccessMsg,
    favErrorMsg,
    removeItem,
  } = useSearchi18n({})
  const { isAuth } = useSelector(selectAuthState)

  const {
    favorites: { id } = {},
    isLoginFavouriteCallback: { isCallBack = false, callBackAssetID = "" },
  } = useSelector(selectFavoritesState)
  const dataUrl = assetID ? `${imageServerUrl}${assetID}` : ""

  useEffect(() => {
    const ogUrl = document.querySelector('meta[property="og:url"]')
      ? document.querySelector('meta[property="og:url"]')?.content
      : ""
    const url = ogUrl
      ? ogUrl.replace(publicWebsiteUrl, baseUrl)
      : `/${window.location.pathname.split(".")[0]}`
    console.log("publicWebsiteUrl=", publicWebsiteUrl)
    console.log("baseUrl=", baseUrl)
    console.log("url=", url)
    console.log("ogUrl=", ogUrl)

    setPageUrl(url)
  }, [])

  const {
    toaster: { content },
  } = useSelector(selectGenericState)

  useEffect(() => {
    if (content) {
      setToasterMessage()
    }
  }, [content])

  useEffect(() => {
    if (
      !isTriggered &&
      isCallBack &&
      isAuth &&
      id &&
      callBackAssetID === assetID
    ) {
      isTriggered = true
      dispatch(
        setLoginFavouriteCallback({ isCallBack: false, callBackAssetID: "" })
      )
      onFavClick({})
    }
  }, [isCallBack, callBackAssetID, isAuth, id])

  const setToasterMessage = async () => {
    const { successMessage, unfavoriteSuccessMessage } = content
    setToasterContent({
      successMessage: await getShortenedUrl(successMessage),
      unfavoriteSuccessMessage: await getShortenedUrl(unfavoriteSuccessMessage),
    })
  }

  const onFavClick = event => {
    event?.preventDefault && event?.preventDefault()
    event?.stopPropagation && event?.stopPropagation()
    let payload = {}
    const id = favEle.current.getAttribute("data-id")
    window.zoneName = zone
    window.internalLinkType = title
    if (!isAuth) {
      handleLogin()
      return
    }
    // For images
    if (assetID) {
      payload = getPayload({ type: "image" })
      if (!id) {
        postData(payload)
      }
    }
    // For Article types
    else {
      Promise.all([
        aemAxiosInstance({
          url: GLOBAL_CONFIGURATION_ENDPOINT,
          params: {
            path: `${ARTICLE_CONTENT_END_POINT}`,
          },
        }),
        aemAxiosInstance({
          url: GLOBAL_CONFIGURATION_ENDPOINT,
          params: {
            path: `${ARTICLE_IMG_END_POINT}`,
          },
        }),
      ])
        .then(response => {
          const [{ data } = {}, { data: { fileReference } = {} }] = response
          payload = getPayload({
            type: "article",
            content: data,
            thumbnail: fileReference,
          })
          postData(payload)
        })
        .catch(error => {
          // eslint disable next-line no-console
          console.error(error)
        })
    }
    if (id) {
      dispatch(
        removeItems({
          ids: [id],
          section: payload?.section,
          imageName: payload?.imageName,
          isDataLayerNeeded: true,
        })
      )
        .unwrap()
        .then(response => {
          dispatch(
            showToast({
              message: toasterContent.unfavoriteSuccessMessage || removeItem,
              isVisible: true,
            })
          )
          favEle.current.removeAttribute("data-id")
          favEle.current.classList.remove("favorite-filled")
        })
        .catch(error => {
          // eslint disable next-line no-console
          console.error(error)
        })
      return
    }
  }

  const postData = payload => {
    if (isAuth) {
      dispatch(addMedia(payload))
    } else {
      handleLogin()
    }
  }

  const getPayload = ({ type, content, thumbnail }) => {
    const payload = {
      type,
      content,
      thumbnail,
      successCallback,
      errorCallback,
      section: zone,
    }
    if (type === "image") {
      payload["name"] = alt
      payload["url"] = dataUrl
      payload["section"] = "main content area"
      payload["imageName"] = assetID.includes("/")
        ? `${assetID.split("/").at(1)}`.toLowerCase()
        : `${assetID}`.toLowerCase()
    } else if (type === "article") {
      payload["caption"] = title
      payload["name"] = content["jcr:title"]
      payload["description"] = content["searchDescription"]
      payload["articleType"] = content["eyeBrowText"]
      payload["thumbnail"] = thumbnail
      payload["url"] = window.location.href
    }
    return payload
  }
  const handleLogin = () => {
    dispatch(
      setLoginFavouriteCallback({
        isCallBack: true,
        callBackAssetID: assetID,
      })
    )
    dispatch(setAuthModalVisibility({ show: true }))
  }
  const successCallback = () => {
    dispatch(
      showToast({
        message: toasterContent.successMessage || favSuccessMsg,
        isVisible: true,
      })
    )
    setIsFavourite(true)
  }
  const errorCallback = () => {
    dispatch(
      showToast({
        message: favErrorMsg,
        isVisible: true,
      })
    )
    setIsFavourite(true)
  }
  return (
    <div
      className={`favorite fav-trigger ${isFavourite ? "favorite-filled" : ""}`}
      aria-label="favorite"
      data-asset={assetID}
      data-url={dataUrl}
      data-title={alt}
      data-internal-link-type={title}
      ref={favEle}
      onClick={event => onFavClick(event)}
    >
      &nbsp;
      <span>{favoritesLabel}</span>
    </div>
  )
}
export default FavouritesUtility
