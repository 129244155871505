import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import _get from "lodash/get"
import qs from "qs"
import _isEmpty from "lodash/isEmpty"
import { getConfig } from "@/constants/config"
import { apim } from "@/constants/api"
import { getLocationByCookies, getUserPersona } from "@/utils/helper"
import ProductCardView from "@/components/productCard/v1/ProductCard.view"
import ProductCardService from "@/components/productCard/v1/ProductCardService"
import useProductCardi18n from "@/i18n/useProductCardi18n"
import { selectCartState } from "@/store/features/cartSlice"
import { GLOBAL_CONFIGURATION_ENDPOINT } from "@/constants/index"
import { aemAxiosInstance } from "@/constants/api"
import MissingImage from "@/public/images/image-missing.png"

const ProductCard = props => {
  const {
    data: authorData,
    data: { presetConfigs },
    singleSku,
  } = props
  const { cart } = useSelector(selectCartState)
  const [state] = useState({
    productSkuId:
      authorData.type === "productSku"
        ? _get(authorData, "productSkuId", "")
        : _get(authorData, "serviceSkuId", ""),
    newTab: _get(authorData, "newTab", false),
    skuType: _get(authorData, "type", ""),
    imagePosition: _get(authorData, "imagePosition", ""),
    jsonFileUrl: _get(authorData, "jsonFilePath", ""),
    swatchUrl: authorData.swatchUrl,
  })

  const [productDetailData, setProductDetailData] = useState({})
  const [userZipCode, setUserZipCode] = useState("")
  const [isNearest, setIsNearest] = useState(false)
  const [isNearestValueUpdated, setIsNearestValueUpdated] = useState(false)
  const [zipCodeData, setZipCodeData] = useState([])
  const [info, setInfo] = useState({})
  const staticTexts = useProductCardi18n({})
  const persona = getUserPersona()
  useEffect(() => {
    getConfig().then(async config => {
      const brandName = config?.general?.siteName ?? ""
      const language = _get(config, "internationalization.language", "en")
      const locale = _get(config, "internationalization.locale", "")
      const currencySign = _get(config, "internationalization.currencySign", "")
      const currencyCode = _get(config, "internationalization.currencyCode", "")

      const { general: { lwAppName = "" } = {} } = config ?? {}

      aemAxiosInstance({
        url: GLOBAL_CONFIGURATION_ENDPOINT,
        params: {
          path: state.jsonFileUrl,
        },
      })
        .then(response => {
          if (response && response.data) {
            setZipCodeData(response.data.zipcode ?? [])
          }
        })
        .catch(err => {
          console.error("Failed to get compare attributes JSON file", err)
        })

      const info = {
        locale: locale,
        language: language,
        currencySign: currencySign,
        currencyCode: currencyCode,
        brandName: brandName,
        lwAppName,
      }

      setInfo(info)
    })
  }, [])

  useEffect(() => {
    try {
      if (!_isEmpty(info)) {
        fetchFromSku()
      }
    } catch (error) {
      console.error(error)
    }

    getUserLocation()
  }, [info])

  const getUserLocation = () => {
    getLocationByCookies().then(getLocation => {
      const userZipCode = getLocation.zipcode ?? ""
      checkUserZipCode(userZipCode)
    })
  }
  const checkUserZipCode = (
    userZipCodeVal,
    callBack,
    needAnalytics = false
  ) => {
    const newCodes = []
    zipCodeData.map(codes => {
      Object.keys(codes).forEach(key => {
        newCodes.push(codes[key].includes(userZipCodeVal))
      })
    })
    const isNearest = newCodes.find(result => result === true) ? true : false

    setIsNearest(isNearest)
    setIsNearestValueUpdated(needAnalytics)
    callBack && callBack(isNearest)
  }

  const getZipCode = (zipCodeVal, needAnalytics = false) => {
    setUserZipCode(zipCodeVal)
    checkUserZipCode(zipCodeVal, needAnalytics)
  }

  const fetchFromSku = () => {
    const fl = [
      "id",
      "slug_s",
      "productName_s",
      "masterSKU_s",
      "Product_Category",
      "ProductBrandName_s",
      "productImages.url_s",
      "productImages.url_ss",
      "productImageVariantImages.url_ss",
      "SKUColorSwatchFilename_s",
      "SKUColorSwatchFilename_ss",
      "ProductDescriptionProductShort_s",
      `priceStartingAt.${persona}_d`,
      `discountedPriceStartingAt.${persona}_d`,
      `priceList.${persona}.saleOffer_s`,
      "Color.SKU.Details_ss",
      "Color.SKU.Details_s",
      "ProductWebFeatures_ss",
      "ProductWebFeatures_s",
      "ProductExclusive_s",
      "ProductIsExclusive_s",
      "RegionReleaseforShipment_dt",
      "RegionLaunchDate_dt",
      "title_s",
      "ProductNarrativeDescription_s",
      `priceList.${persona}.finalPrice_d`,
      "CustomerFacingBrand_s",
      "RegionBrandName_s",
      "productImages.labelWithUrl_ss",
    ]
    let fqSkuParam = singleSku ? 'sku_s':'variantList.sku_ss'
    apim
      .get(`/search/plp`, {
        params: {
          fl: fl.join(","),
          q: "*:*",
          fq: [
            `${fqSkuParam}:("${state.productSkuId}")`,
            `language_s:("${info.language}")`,
          ],
          collections: info.lwAppName.toLowerCase(),
          profilename: `profile_${info.lwAppName.toLowerCase()}_General`,
        },
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: "repeat", encode: false })
        },
      })
      .then(res => {
        if (res && res.data) {
          const productData = _get(res.data, "response.docs[0]", {})
          setProductDetailData(productData)
        } else {
          console.error("Error occured while fetching product data")
        }
      })
  }

  const productCardProps = {}
  productCardProps.presetConfigs = presetConfigs ?? ""
  productCardProps.swatchUrl = state.swatchUrl ?? ""

  if (productDetailData) {
    // get product images
    if (productDetailData["modifiedproductImageVariantImages.url_ss"]) {
      const imageStrig =
        productDetailData["modifiedproductImageVariantImages.url_ss"]?.split(
          "|"
        )
      productCardProps.images = imageStrig.length
        ? imageStrig[imageStrig.length - 1]
        : ""
    } else if (productDetailData["productImages.url_s"]) {
      productCardProps.images = productDetailData["productImages.url_s"] ?? ""
    } else if (singleSku && productDetailData["productImages.labelWithUrl_ss"]){
      productCardProps.images =
      productDetailData["productImages.labelWithUrl_ss"].filter(val => val.includes(state.productSkuId))[0]?.split('|')[5] ?? "";
    } else if (productDetailData["productImages.url_ss"]) {
      productCardProps.images =
        productDetailData["productImages.url_ss"][0] ?? ""
    } else {
      productCardProps.images = MissingImage?.src
    }

    // get product colors
    if (productDetailData["Color.SKU.Details_ss"]) {
      const colors = productDetailData["Color.SKU.Details_ss"] ?? []
      productCardProps.colors = colors.filter(item => {
        const color = item.split("|")
        return color[4] === "false" || Date.parse(color[4]) > Date.now()
      })
    } else {
      productCardProps.colors = [
        productDetailData["SKUColorSwatchFilename_s"] ?? "",
      ]
    }

    // get product web features
    if (productDetailData["ProductWebFeatures_s"]) {
      productCardProps.productFeatures =
        [productDetailData["ProductWebFeatures_s"]] ?? []
    } else {
      productCardProps.productFeatures =
        productDetailData["ProductWebFeatures_ss"] ?? []
    }

    productCardProps.currencySign = info.currencySign
    productCardProps.productCategory = _get(
      productDetailData,
      "Product_Category",
      ""
    )
    productCardProps.name = _get(productDetailData, "productName_s", "")
    productCardProps.brandName = _get(
      productDetailData,
      "RegionBrandName_s",
      ""
    )
    productCardProps.description = _get(productDetailData, "title_s", "")
    productCardProps.narrativeDescription = _get(
      productDetailData,
      "ProductNarrativeDescription_s",
      ""
    )
    productCardProps.price = _get(
      productDetailData,
      `priceStartingAt.${persona}_d`,
      0
    )
    productCardProps.discountedPrice = _get(
      productDetailData,
      `discountedPriceStartingAt.${persona}_d`,
      0
    )
    productCardProps.discountPercent = parseInt(
      _get(productDetailData, `priceList.${persona}.saleOffer_s`, 0)
    )
    productCardProps.slug = _get(productDetailData, "slug_s", "")
    productCardProps.customerBrand = _get(
      productDetailData,
      "CustomerFacingBrand_s",
      ""
    )
    let tempColorDetails = _get(
      productDetailData,
      "Color.SKU.Details_s",
      _get(productDetailData, "Color.SKU.Details_ss", null)
    )
    if (tempColorDetails) {
      if (typeof tempColorDetails !== "string" && tempColorDetails[0]) {
        tempColorDetails = tempColorDetails[0]
      }
      productCardProps.customerFacingSKU = tempColorDetails.split("|")[5] || ""
    }
    productCardProps.badgeVal = _get(
      productDetailData,
      `priceList.${persona}.saleOffer_s`,
      ""
    )
    productCardProps.globalDiscount =
      productCardProps.price -
      _get(
        productDetailData,
        `priceList.${persona}.finalPrice_d`,
        productCardProps.price
      )
    const productCategory = productCardProps?.productCategory?.toLowerCase()
    productCardProps.priceType = ""
    if (
      productCategory === "field tiles" ||
      productCategory === "slabs" ||
      productCategory === "mosaics"
    ) {
      productCardProps.priceType = "/" + staticTexts?.sfLabel
    }
    if (productCategory === "trims & moldings") {
      productCardProps.priceType = "/" + staticTexts?.lfLabel
    }
    if (productCategory === "grout") {
      productCardProps.priceType = staticTexts?.boxLabel
    }
  }

  const resetNearestValueUpdateFlag = () => {
    setIsNearestValueUpdated(false)
  }

  return state.skuType !== "productSku" &&
    !_isEmpty(state.productSkuId) &&
    !_isEmpty(productCardProps) ? (
    <ProductCardService
      {...productCardProps}
      imagePosition={state.imagePosition}
      newTab={state.newTab}
      staticTexts={staticTexts}
      currencySign={info.currencySign}
      userZipCode={userZipCode}
      isNearest={isNearest}
      skuId={state.productSkuId}
      getZipCode={getZipCode}
      resetNearestValueUpdateFlag={resetNearestValueUpdateFlag}
      isNearestValueUpdated={isNearestValueUpdated}
      cart={cart}
    />
  ) : (
    <ProductCardView
      {...productCardProps}
      product={productCardProps}
      i18n={staticTexts}
      currencySign={info.currencySign}
      presetConfigs={presetConfigs}
      title={productCardProps?.brandName}
      skuId={state.productSkuId}
    />
  )
}

export default ProductCard
